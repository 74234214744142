import React from 'react'
//

export const FederalFooter = ({ logo, children }) => {
  return (
    <div className="bg-c-fed-footer-bg text-c-fed-footer-text w-full flex relative mx-auto px-6 py-8 md:p-5">
      <div className="container max-w-7xl flex flex-row justify-between mx-auto">
        <div className="w-full flex flex-col justify-end">
          {logo && logo?.url && (
            <div className="text-center mb-4">
              <img typeof="foaf:Image" src={logo?.url} width="40" height="40" alt={logo?.alt} className="mx-auto" />
            </div>
          )}
          {children && <div className="text-sm text-center">{children}</div>}
        </div>
      </div>
    </div>
  )
}

export default FederalFooter
