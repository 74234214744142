export const onValidateField = ({ key, value, type, required, formData, state }) => {
  let invalid = false
  let error = ''
  const valueToUse = value || formData?.[key]

  if (type === 'checkbox' || type === 'radio') {
    if (type === 'checkbox') {
      const nameSimplified = key?.split('[')?.[0]
      if (required) {
        const selectedKeys = Object.keys(formData)?.filter((i) => i?.startsWith(nameSimplified))
        const selectedOptions = selectedKeys.filter((i) => {
          return formData[i]
        })
        if (Array.isArray(selectedKeys) && selectedOptions.length < 1) {
          invalid = true
          error = state?.intl?.formatMessage({ id: 'form.requiredOptionValidation' })
        }
      }
    }
    if (type === 'radio') {
      if (required) {
        if (!valueToUse) {
          invalid = true
          error = state?.intl?.formatMessage({ id: 'form.requiredOptionValidation' })
        }
      }
    }
  } else if (
    type === 'text' ||
    type === 'url' ||
    type === 'email' ||
    type === 'number' ||
    type === 'tel' ||
    type === 'textarea'
  ) {
    if (required) {
      if (!valueToUse) {
        invalid = true
        error = state?.intl?.formatMessage({ id: 'form.requiredValidation' })
      }
    }
    if (!invalid) {
      if (valueToUse) {
        if (type === 'number') {
          if (!/^[0-9,.]{0,}$/gim.test(valueToUse)) {
            invalid = true
            error = state?.intl?.formatMessage({ id: 'form.numberValidation' })
          }
        }
        if (type === 'tel') {
          if (!/^[a-zA-Z0-9+()-/\s#]{0,}$/gim.test(valueToUse)) {
            invalid = true
            error = state?.intl?.formatMessage({ id: 'form.telValidation' })
          }
        }
        if (type === 'url') {
          if (!/^(mailto|tel|ftp|http|https):\/\/[^ "]+$/gim.test(valueToUse)) {
            invalid = true
            error = state?.intl?.formatMessage({ id: 'form.urlValidation' })
          }
        }
        if (type === 'email') {
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/gim.test(valueToUse)) {
            invalid = true
            error = state?.intl?.formatMessage({ id: 'form.emailValidation' })
          }
        }
      }
    }
  } else if (type === 'select') {
    if (required && !valueToUse) {
      invalid = true
      error = state?.intl?.formatMessage({ id: 'form.requiredOptionValidation' })
    }
  } else if (type === 'file') {
    if (required && !valueToUse?.isUploaded && !valueToUse?.fids) {
      invalid = true
      error = state?.intl?.formatMessage({ id: 'form.requiredFileValidation' })
    }
  } else if (type === 'date') {
    if (required && !valueToUse?.dateValue) {
      invalid = true
      error = state?.intl?.formatMessage({ id: 'form.requiredDateValidation' })
    }
  } else if (type === 'time') {
    if (required && !valueToUse?.timeValue) {
      invalid = true
      error = state?.intl?.formatMessage({ id: 'form.requiredTimeValidation' })
    }
  }

  return { validated: true, valid: !invalid, error }
}

export default function reducer(state = { formValid: false, initialized: false, formData: {}, formState: {} }, action) {
  switch (action.type) {
    case 'set_intl': {
      const { intl } = action.payload

      return { ...state, intl }
    }
    case 'set_form_initialized': {
      return { ...state, initialized: true }
    }
    case 'set_form_state': {
      const { formState } = action.payload
      const newFormState = { ...state.formState }
      Object.keys(formState).forEach((key) => {
        if (!newFormState[key]) {
          newFormState[key] = { ...formState[key] }
        }
      })

      return { ...state, formState: newFormState }
    }
    case 'update_field': {
      const { key, value, type, required } = action.payload
      const newFormData = { ...state.formData }
      newFormData[key] = value
      const newFormState = { ...state.formState }
      const fieldValidation = onValidateField({
        state,
        key: key?.split('[')?.[0],
        value,
        type,
        required,
        formData: newFormData
      })
      newFormState[key?.split('[')?.[0]] = { ...newFormState[key?.split('[')?.[0]], ...fieldValidation }

      return {
        ...state,
        formData: newFormData,
        formState: newFormState
      }
    }
    case 'blur_field': {
      const { key } = action.payload
      const newFormState = { ...state.formState }
      newFormState[key?.split('[')?.[0]] = { ...newFormState[key?.split('[')?.[0]], blur: true }

      return {
        ...state,
        formState: newFormState
      }
    }
    case 'validate_single_field': {
      const { key } = action.payload
      const newFormState = { ...state.formState }
      const fieldValidation = onValidateField({
        state,
        formData: state?.formData || {},
        key: key?.split('[')?.[0],
        type: newFormState[key?.split('[')?.[0]]?.type,
        required: newFormState[key?.split('[')?.[0]]?.required
      })
      newFormState[key] = { ...newFormState[key], ...fieldValidation }

      return {
        ...state,
        formState: newFormState
      }
    }
    case 'validate_all_fields': {
      const newFormState = { ...state.formState }
      let formValid = true
      Object.keys(newFormState).forEach((key) => {
        const fieldValidation = onValidateField({
          state,
          formData: state?.formData || {},
          key: key?.split('[')?.[0],
          type: newFormState[key?.split('[')?.[0]]?.type,
          required: newFormState[key?.split('[')?.[0]]?.required
        })
        if (!fieldValidation?.valid) {
          formValid = false
        }
        newFormState[key] = { ...newFormState[key], ...fieldValidation }
      })

      return {
        ...state,
        formState: newFormState,
        formValid
      }
    }
    case 'prepopulate_form': {
      const { errors, values } = action.payload
      const newFormData = { ...state.formData }
      if (values) {
        Object.keys(values).forEach((key) => {
          newFormData[key] = values?.[key]
        })
      }
      const newFormState = { ...state.formState }

      if (errors && errors?.error) {
        Object.keys(errors?.error).forEach((key) => {
          newFormState[key] = {
            ...newFormState[key],
            ...{ valid: false, validated: true, blur: true, error: errors?.error?.[key] }
          }
        })
      }

      return {
        ...state,
        formData: newFormData,
        formState: newFormState,
        ...(errors !== false ? { formValid: false } : {})
      }
    }
    default: {
      return { ...state }
    }
  }
}
