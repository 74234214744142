import React, { useEffect } from 'react'
import tw, { styled, theme } from 'twin.macro'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
//
import FormattedText from '~utils/text'
import InputLabel from '../input-label'

const Container = styled.div`
  transition: border 0.24s ease-in-out;
`

const ErrorMessage = styled.div`
  ${tw`text-xs text-c-input-error pt-1`}
`

const Loader = styled.div`
  ${tw`ease-linear rounded-full border-8 border-t-8 border-c-uploads-loaderBg h-16 w-16`}
  border-top-color: ${theme`colors['c-uploads'].loaderFg`};
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;

  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const mimeTypes = {
  gif: 'image/gif',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  bmp: 'image/x-ms-bmp',
  eps: 'application/postscript',
  tif: 'image/tiff',
  pict: 'image/pict',
  psd: 'image/x-photoshop',
  txt: 'text/plain',
  rtf: 'application/rtf',
  html: 'text/html',
  odf: 'application/vnd.oasis.opendocument.formula',
  pdf: 'application/pdf',
  doc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml: 'application/xml',
  avi: 'video/x-msvideo',
  mov: 'video/quicktime',
  mp3: 'audio/mpeg',
  mp4: 'video/mpeg',
  ogg: 'audio/ogg',
  wav: 'audio/wav',
  bz2: 'application/x-bzip2',
  dmg: 'application/x-apple-diskimage',
  gz: 'application/gzip',
  jar: 'application/java-archive',
  rar: 'application/rar',
  sit: 'application/x-stuffit',
  svg: 'image/svg+xml',
  tar: 'application/x-tar',
  zip: 'application/zip'
}

const STATUS = {
  uploading: 1,
  uploaded: 2
}

export const FilesInput = ({
  field = {},
  label = '',
  hasError = false,
  tabIndex,
  files = [],
  isUploading = false,
  isUploaded = false,
  maxUploadSize = 20 * 1024 * 1024,
  onUpload = () => {
    /* noop */
  },
  onInitialRender = () => {
    /* noop */
  },
  onReset = () => {
    /* noop */
  }
}) => {
  const intl = useIntl()

  const accept = field?.accept
    ?.split(',')
    .map((ext) => ext.trim())
    .map((ext) => mimeTypes?.[ext])
    .filter((mime) => mime)
    ?.join(', ')

  const settings = {
    accept,
    minSize: 0,
    maxSize: maxUploadSize,
    maxFiles: 10
  }

  if (!field?.multiple) {
    settings.maxFiles = 1
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone(settings)

  useEffect(() => {
    onInitialRender()
  }, [])

  useEffect(() => {
    if (acceptedFiles?.length) {
      onUpload(acceptedFiles)
    }
  }, [acceptedFiles])

  const Processing = ({ title, status }) => (
    <aside className="justify-center p-2 text-center">
      <h4>{title}</h4>
      <ul className="list-none">
        {files?.map((file) => (
          <li key={file?.name} className="mt-1 text-sm">
            {file?.name}
          </li>
        ))}
      </ul>
      {status === STATUS.uploaded ? (
        <div className="mt-2">
          <button
            onClick={onReset}
            type="button"
            className="bg-c-uploads-buttonBg text-c-uploads-buttonFg border border-solid border-c-uploads-buttonFg px-8 py-2 font-bold"
          >
            {intl.formatMessage({ id: 'form.resetUpload' })}
          </button>
        </div>
      ) : null}
    </aside>
  )

  return (
    <div className="flex flex-col flex-wrap" tabIndex={tabIndex || undefined}>
      {label ? <InputLabel htmlFor={field.name} text={label} /> : null}
      <div
        className="container flex-1 flex-col justify-center items-center p-5 border-dashed outline-none border-2 border-c-uploads-border bg-c-uploads-bg text-c-uploads-fg"
        css={[
          isDragActive && tw`border-c-uploads-borderDragging`,
          isDragAccept && tw`border-c-uploads-borderActive`,
          isDragReject && tw`border-c-form-error`
        ]}
      >
        <Container
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          css={[isDragReject && tw`border-c-form-error`]}
        >
          {!isUploaded ? <input name={field.name} {...getInputProps()} /> : null}
          {!isUploading && !isUploaded ? (
            <>
              <div className="flex flex-wrap justify-center p-2 text-center text-md font-bold">
                {intl.formatMessage({ id: 'form.drop' })}
              </div>
              <div className="flex flex-wrap justify-center text-center text-md">
                {intl.formatMessage({ id: 'form.or' })}
              </div>
              <div className="flex flex-wrap justify-center p-2 text-center text-md">
                <button
                  type="button"
                  className="bg-c-uploads-buttonBg text-c-uploads-buttonFg border border-solid border-c-uploads-buttonFg px-8 py-2 font-bold"
                >
                  {intl.formatMessage({ id: 'form.browse' })}
                </button>
              </div>
              <div className="flex flex-wrap justify-center p-2 text-center text-sm">{field?.description}</div>
            </>
          ) : null}
        </Container>
        {isUploading ? (
          <>
            <div className="flex justify-center items-center mt-8 mb-2">
              <Loader />
            </div>
            <Processing title={intl.formatMessage({ id: 'form.uploading' })} status={STATUS.uploading} />
          </>
        ) : null}

        {isUploaded ? (
          <Processing title={intl.formatMessage({ id: 'form.uploaded' })} status={STATUS.uploaded} files={files} />
        ) : null}
      </div>
      {hasError && field?.error ? (
        <ErrorMessage>
          <FormattedText format="html" text={field.error} />
        </ErrorMessage>
      ) : null}
    </div>
  )
}
