import React from 'react'
import { theme, styled } from 'twin.macro'
//

const h1 = styled.h1``
const h2 = styled.h2``
const h3 = styled.h3``
const h4 = styled.h4`
  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
    font-size: 16px;
    line-height: 26px;
  }
`
const h5 = styled.h5``
const h6 = styled.h6``

const variants = { h1, h2, h3, h4, h5, h6 }

export const Heading = ({ children, text, level = 'h1', id = '', style = {}, ...rest }) => {
  const HeadingComp = variants[level] || variants.h1
  if(children || text) {
    return <HeadingComp id={id} style={style} {...rest}>{children || text}</HeadingComp>
  }
  return ""
}

export default Heading
