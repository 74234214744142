import React, { useEffect, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { useComponentVisible } from '~utils/dropdown'
import FormattedText from '~utils/text'
//

const InputLabel = styled.label(({ disabled }) => [tw`text-c-input-select-label mb-2`, disabled && tw`opacity-40`])

const SelectButton = styled.button(({ haserror, disabled }) => [
  tw`relative w-full pr-10 py-2 px-2 text-lg min-h-input text-c-input-select-select border border-solid border-c-input-select-selectList placeholder-c-input-select-placeholder rounded-sm outline-none`,
  tw`hover:(border-c-input-select-border)`,
  tw`focus:(border-c-input-select-border outline-none)`,

  haserror && tw`text-c-input-select-error border-c-input-select-error`,
  disabled && tw`opacity-40 cursor-not-allowed`
])

const SelectLabel = styled.span(({ haserror, filled }) => [
  tw`ml-3 block truncate`,
  haserror && tw`text-c-input-select-error`,
  !filled && tw`text-c-input-select-selectLabel italic`
])

const SelectList = styled.ul(() => [
  tw`bg-white max-h-60 overflow-auto focus:outline-none p-0 pt-2 m-0 border border-solid border-t-0 border-c-input-select-selectList`,
  css`
    z-index: 1;
    display: block;
    position: relative;
  `
])

const SelectOption = styled.button(({ disabled, selected }) => [
  tw`text-left w-full bg-white cursor-pointer border-b border-solid border-c-input-select-selectList py-4 px-5 text-sm text-c-input-select-selectOption flex items-center relative overflow-hidden`,
  tw`hover:(bg-c-input-select-selectedOptions)`,

  css`
    z-index: 1;
    &:last-child {
      border: 0;
    }
  `,

  disabled && tw`opacity-40 cursor-not-allowed`,
  selected && tw`bg-c-input-select-selectedOptions`
])

const SelectChevron = styled.span`
  ${css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' fill='%23323341' aria-hidden='true'%3E%3Cpolygon fill='currentColor' points='0,12.438 48,12.438 24,35.562 ' /%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  `}
  ${tw`absolute inset-y-0 right-5 flex items-center pointer-events-none pt-3 pr-3 text-black`}
`

const ErrorMessage = styled.div`
  ${tw`text-xs text-c-input-select-error pt-1`}
`

export const TimeSelect = ({
  field = {},
  label = '',
  value = '',
  haserror = false,
  disabled = false,
  focus = false,
  tabIndex,
  onSelect = () => {
    /* noop */
  },
  onBlur = () => {
    /* noop */
  },
  onInitialRender = () => {
    /* noop */
  }
}) => {
  const {
    ref: refHour,
    isComponentVisible: isHourVisible,
    setIsComponentVisible: setHourVisible
  } = useComponentVisible(false)
  const { ref: refMin, isComponentVisible: isMinVisible, setIsComponentVisible: setMinVisible } = useComponentVisible(
    false
  )
  const [isRendered, setIsRendered] = useState(false)
  const [hValue, mValue] = value?.split(':')

  useEffect(() => {
    setIsRendered(true)
  }, [])

  const onSelectHour = (opt) => {
    onSelect(`${opt}:${mValue || '00'}`)
    setHourVisible(false)
  }

  const onSelectMinute = (opt) => {
    onSelect(`${hValue || '01'}:${opt}`)
    setMinVisible(false)
  }

  useEffect(() => {
    onInitialRender()
  }, [])

  return (
    <div className="flex flex-col flex-wrap" tabIndex={tabIndex || undefined}>
      {label && (
        <InputLabel htmlFor={field.name} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <>
        <div className="flex flex-row">
          <div ref={refHour} className="w-40">
            <div className="relative">
              <SelectButton
                haserror={haserror}
                type="button"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                onMouseDown={
                  !disabled
                    ? (event) => {
                        // eslint-disable-next-line no-unused-expressions
                        event?.stopPropagation()
                        setHourVisible(!isHourVisible)
                      }
                    : () => {
                        /* noop */
                      }
                }
                onFocus={
                  !disabled
                    ? () => {
                        if (!isHourVisible) {
                          setHourVisible(true)
                        }
                      }
                    : () => {
                        /* noop */
                      }
                }
                disabled={disabled}
              >
                <span className="flex items-center">
                  <SelectLabel haserror={haserror} filled={hValue}>
                    {hValue || 'Hour'}
                  </SelectLabel>
                </span>
                <SelectChevron />
              </SelectButton>
              {(isHourVisible || !isRendered) && (
                <div className={`bg-white w-full ${isRendered ? 'absolute' : 'relative'}`}>
                  <SelectList
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant={`listbox-item-${hValue}`}
                  >
                    {[...Array(24).keys()]
                      .map((idx) => idx + 1)
                      .map((idx) => {
                        const hour = idx > 9 ? idx : `0${idx}`

                        return (
                          <SelectOption
                            key={hour}
                            role="option"
                            selected={hour === hValue}
                            onClick={() => onSelectHour(hour)}
                            onBlur={() => {
                              if (hour === hValue) {
                                setHourVisible(false)
                              }
                            }}
                          >
                            <div className="flex items-center">
                              <span className="block font-normal truncate">{hour}</span>
                            </div>
                          </SelectOption>
                        )
                      })}
                  </SelectList>
                </div>
              )}
            </div>
          </div>
          <div ref={refMin} className="ml-4 w-40">
            <div className="relative">
              <SelectButton
                haserror={haserror}
                type="button"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                onMouseDown={
                  !disabled
                    ? (event) => {
                        // eslint-disable-next-line no-unused-expressions
                        event?.stopPropagation()
                        setMinVisible(!isMinVisible)
                      }
                    : () => {
                        /* noop */
                      }
                }
                onFocus={
                  !disabled
                    ? () => {
                        if (!isMinVisible) {
                          setMinVisible(true)
                        }
                      }
                    : () => {
                        /* noop */
                      }
                }
                disabled={disabled}
              >
                <span className="flex items-center">
                  <SelectLabel haserror={haserror} filled={mValue}>
                    {mValue || 'Minutes'}
                  </SelectLabel>
                </span>
                <SelectChevron />
              </SelectButton>
              {(isMinVisible || !isRendered) && (
                <div className={`bg-white w-full ${isRendered ? 'absolute' : 'relative'}`}>
                  <SelectList
                    role="listbox"
                    aria-labelledby="listbox-label"
                    aria-activedescendant={`listbox-item-${mValue}`}
                  >
                    {[...Array(60).keys()].map((idx) => {
                      const min = idx > 9 ? idx : `0${idx}`
                      return (
                        <SelectOption
                          key={min}
                          role="option"
                          selected={min === mValue}
                          onClick={() => onSelectMinute(min)}
                          onBlur={() => {
                            if (min === mValue) {
                              setMinVisible(false)
                            }
                          }}
                        >
                          <div className="flex items-center">
                            <span className="block font-normal truncate">{min}</span>
                          </div>
                        </SelectOption>
                      )
                    })}
                  </SelectList>
                </div>
              )}
            </div>
          </div>
        </div>
      </>

      {haserror && field.error && (
        <ErrorMessage>
          <FormattedText format="html" text={field.error} />
        </ErrorMessage>
      )}
    </div>
  )
}
