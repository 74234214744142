import React from 'react'
import { FormattedMessage } from 'react-intl'
//
import { SiteDataContext } from '~components/layout/wrappers'
import Footer, { FederalFooter, LegalFooter } from '~storybook/footers'
import NavLink from '~utils/navlink'
import logoUrl from '~images/be-logo.png'
import { getSrcImage } from '~storybook/image'

const logo = {
  url: logoUrl,
  alt: 'Logo of the Belgian Federal Authorities'
}

const link = {
  url: 'https://www.belgium.be',
  label: 'belgium.be'
}

const FooterWrapper = ({ navigation = [], legal = [] }) => {
  const data = React.useContext(SiteDataContext)

  const { group } = data

  const textBlock = {
    title: group?.fieldFooterFreeTextTitle,
    content: group?.fieldFooterFreeText?.value,
    format: group?.fieldFooterFreeText?.format
  }

  const footerLogoUrl =
    group?.fieldFooterLogo?.entity?.thumbnail?.url && getSrcImage(group?.fieldFooterLogo?.entity?.thumbnail?.url)

  const socialLinks = []

  if (group?.fieldFooterSocialMediaLinks) {
    if (group?.fieldSocialmediaTwitter?.url.path) {
      socialLinks.push({ url: group?.fieldSocialmediaTwitter?.url.path })
    }
    if (group?.fieldSocialmediaFacebook?.url.path) {
      socialLinks.push({ url: group?.fieldSocialmediaFacebook?.url.path })
    }
    if (group?.fieldSocialmediaInstagram?.url.path) {
      socialLinks.push({ url: group?.fieldSocialmediaInstagram?.url.path })
    }
    if (group?.fieldSocialmediaLinkedin?.url.path) {
      socialLinks.push({ url: group?.fieldSocialmediaLinkedin?.url.path })
    }
  }

  return (
    <div data-search-ignore="true">
      <Footer
        textBlock={textBlock}
        linkOptions={navigation}
        socialLinks={socialLinks}
        logo={{ url: footerLogoUrl, alt: group?.fieldFooterFreeTextTitle }}
      />
      {group?.fieldFooterFederal ? (
        <FederalFooter logo={logo}>
          <>
            <span>
              <FormattedMessage id="footer.message" />
            </span>
            <NavLink
              className="ml-1 text-tuna-500 font-thin block md:inline no-underline hover:underline focus:underline"
              to={link.url}
            >
              {link.label}
            </NavLink>
          </>
        </FederalFooter>
      ) : null}
      <LegalFooter links={legal} />
    </div>
  )
}

export default FooterWrapper
