import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import { useIntl } from 'react-intl'
//
import { InputField } from '~storybook/form/input/input'
import Icons from '~storybook/icons/index'

const IconButton = styled.button`
  ${tw`text-white font-medium py-3 px-4 rounded-r-sm transition-colors duration-200 flex justify-center min-w-button`}
  ${tw`bg-c-header-search-searchButton hover:bg-c-header-search-searchButtonHover focus:bg-c-header-search-searchButtonHover active:bg-c-header-search-searchButtonHover focus:outline-none px-3 min-w-0`}
  ${({ disabled }) => disabled && tw`opacity-40 cursor-default hover:bg-c-header-search-searchButtonDisabled`}

  width: 48px;
  height: 46px;
`

const InputWrapper = styled.div`
  width: calc(100%);
  ${tw`sm:w-80 md:w-80`}
`

export const SearchContents = ({
  onSearch = () => {
    /* noop */
  },
  onCloseSearch = () => {
    /* noop */
  }
}) => {
  const intl = useIntl()
  const [string, setString] = useState('')
  const [haserror, setHaserror] = useState(false)

  const onChangeHandler = (str) => {
    setString(str)
  }

  const onSearchHandler = (str) => {
    if (!str) {
      setHaserror(true)
    } else {
      setHaserror(false)
      onSearch(str)
    }
  }

  return (
    <div className="bg-c-header-search-bg w-full flex relative shadow-lg border-t lg:border-t-0 border-solid border-c-header-search-border text-c-typography-text">
      <div className="flex flex-row mx-auto my-8 md:my-10 px-4">
        <InputWrapper>
          <InputField
            search
            field={{
              id: 'search-field',
              value: string || '',
              placeholder: intl.formatMessage({ id: 'search.placeholder' }),
              error: intl.formatMessage({ id: 'search.error' })
            }}
            onChange={(str) => onChangeHandler(str)}
            onKeyDown={(e) => e.key === 'Enter' && onSearchHandler(string)}
            haserror={haserror}
            focus
          />
        </InputWrapper>
        <IconButton onClick={() => onSearchHandler(string)}>
          <Icons.Search />
        </IconButton>
      </div>
      <button
        type="button"
        onFocus={onCloseSearch}
        onClick={() => {
          /* noop */
        }}
      />
    </div>
  )
}
