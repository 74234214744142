import React from 'react'
import Icons from '~storybook/icons/index'

export const getSocialIcon = (url) => {
  switch (true) {
    case /facebook/.test(url): {
      return <Icons.Facebook />
    }
    case /twitter/.test(url): {
      return <Icons.Twitter />
    }
    case /linkedin/.test(url): {
      return <Icons.LinkedIn />
    }
    case /instagram/.test(url): {
      return <Icons.Instagram />
    }
    default: {
      return <Icons.ExternalLink />
    }
  }
}

export default getSocialIcon
