/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import tw, { styled } from 'twin.macro'
import NavLink from '~utils/navlink'
//

export const Disclaimer = styled(({ className, text, children, ...other }) => (
  <small className={`${className || ''} font-normal text-xs`} {...other}>
    {text || children}
  </small>
))`
  line-height: 16px;
`

export const Tag = styled(({ className, text, children, ...other }) => (
  <label
    className={`${className || ''} leading-tight uppercase font-medium tracking-widest font-normal text-xs`}
    {...other}
  >
    {text || children}
  </label>
))`
  line-height: 16px;
  letter-spacing: 2px;
`

export const Paragraph = ({ text = '', children = null, className, ...rest }) => (
  <p className={`${className || ''} font-normal text-base`} {...rest}>
    {text || children}
  </p>
)

export const SuperParagraph = ({ text = '', children = null, className, ...rest }) => (
  <p className={`${className || ''} font-normal text-lg`} {...rest}>
    {text || children}
  </p>
)

export const Label = ({ text = '', children = null, className, ...rest }) => (
  <label className={`${className || ''} font-normal text-sm`} {...rest}>
    {text || children}
  </label>
)

export const Link = styled((props) => <NavLink {...props} />)`
  ${tw`text-white font-thin py-0 cursor-pointer transition-colors duration-200`}
  ${tw`bg-transparent text-c-typography-text hover:text-c-typography-hoverText hover:underline`}
  line-height: 26px;
`
