import facepaint from 'facepaint'
//
export const smallBreakpoint = 480
export const mediumBreakpoint = 768
export const largeBreakpoint = 1200
export const xlargeBreakpoint = 1440

export const breakpoints = [smallBreakpoint, mediumBreakpoint, largeBreakpoint, xlargeBreakpoint]

export default facepaint([
  `@media(min-width: ${smallBreakpoint}px)`,
  `@media(min-width: ${mediumBreakpoint}px)`,
  `@media(min-width: ${largeBreakpoint}px)`,
  `@media(min-width: ${xlargeBreakpoint}px)`
])
