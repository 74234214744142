import React, { useState } from 'react'
import tw, { theme, styled } from 'twin.macro'
import { FormattedMessage } from 'react-intl'
//
import { Heading } from '~storybook/blocks/typography/index'
import Button from '~storybook/button/index'
import InputOptionGroup from '~storybook/form/input-option/index'
import FormattedText from '~utils/text'

const GDPRWrapper = styled.section`
  ${tw`fixed bottom-0 left-0 w-full bg-c-gdpr-bg text-c-gdpr-text`}
  overflow-y: scroll;
  max-height: 100vh;
  z-index: 9999;

  svg {
    width: 32px;
    height: 32px;
    * {
      fill: ${({ danger }) => (danger ? theme`colors['c-gdpr'].dangerLight` : theme`colors['c-gdpr'].dangerDark`)};
    }
  }

  .close svg {
    width: 48px;
    height: 48px;

    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      width: 32px !important;
      height: 32px !important;
    }

    * {
      fill: ${theme`colors['c-gdpr'].closeBtn`};
    }
  }
`

export const GDPR = ({
  text = { value: '', format: 'html' },
  title = '',
  cookiesOptions = [],
  onSubmit = () => {
    /* noop */
  }
}) => {
  const [options, setOptions] = useState(
    cookiesOptions.map((cookie) => {
      if (cookie?.required) {
        return { ...cookie, checked: true }
      }
      return cookie
    })
  )

  return (
    <GDPRWrapper data-search-ignore="true">
      <div className="relative container max-w-7xl content-center justify-between mx-auto px-6 md:px-10 mb-6 mt-6 lg:mb-6 lg:my-6">
        <div className="w-full lg:w-10/12">
          <div className="w-full flex flex-col justify-between items-start">
            <Heading className="mb-2" level="h5">
              {title}
            </Heading>
            <FormattedText text={text?.value} format={text?.format} />
          </div>

          <div className="grid grid-cols-2 gap-4 md:flex mt-6">
            {Array.isArray(options) &&
              options.map((cookie) => {
                return (
                  <div className="md:mr-6 lg:mr-10" key={`cookie-${cookie?.id}`}>
                    <InputOptionGroup
                      {...{
                        field: {
                          name: cookie?.name
                        },
                        options: [{ ...cookie, checked: cookie?.required ? true : cookie?.checked }],
                        radios: false,
                        disabled: false,
                        onUpdate: (state) => {
                          if (!cookie?.required) {
                            const newOptions = [
                              ...options.map((opt) => {
                                if (cookie?.required) {
                                  return { ...cookie, checked: true }
                                }
                                if (opt?.name === state?.[0]?.name) {
                                  return { ...opt, checked: state?.[0]?.checked }
                                }
                                return opt
                              })
                            ]
                            setOptions(newOptions)
                          }
                        },
                        readOnly: cookie?.required
                      }}
                    />
                  </div>
                )
              })}
          </div>

          <div className="flex flex-col md:flex-row">
            <Button className="mt-6 md:mr-4" aria-label="Close" icon={false} onClick={() => onSubmit(options)}>
              <FormattedMessage id="gdpr.allowSelectedCookies" />
            </Button>
          </div>
        </div>
      </div>
    </GDPRWrapper>
  )
}

export default GDPR
