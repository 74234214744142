import React, { useState, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import Icons from '~storybook/icons/index'
import NavLink from '~utils/navlink'
//

const TertiaryStyle = styled(({ hasIcon, isSection, ...other }) => <NavLink {...other} />)`
  ${tw`text-white font-thin py-0 no-underline cursor-pointer transition-colors duration-200 flex justify-center`}
  ${tw`bg-transparent text-c-accordion-secondary hover:text-c-accordion-primary hover:underline`}
  ${({ hasIcon }) => hasIcon && tw`pr-4 justify-start`}
  ${({ isSection }) => (isSection && tw`text-c-accordion-primary font-medium`) || tw`mb-3`}
  svg {
    margin-left: 10px;
    display: inline-block;
  }
`

const AccordionItemWrapper = styled(({ expanded, ...other }) => <div {...other} />)`
  ${tw`block border-b border-solid border-c-accordion-border`}
  ${({ expanded }) => expanded && tw`pb-10`}
`
const AccordionItem = styled(({ active, hasItems, ...other }) => <NavLink {...other} to={other?.to || '#'} />)`
  ${tw`w-full bg-transparent text-lg text-c-accordion-primary hover:text-c-accordion-secondary hover:underline font-thin py-0 mt-3 mb-3 no-underline cursor-pointer transition-colors duration-200 flex justify-between`}
  ${({ hasItems }) => hasItems && tw`no-underline hover:no-underline`}
`

export const MenuContents = ({ navigation, selectedNavItem }) => {
  const [isRendered, setIsRendered] = useState(false)
  useEffect(() => {
    setIsRendered(true)
  }, [])

  return (
    <div className={`bg-white w-full flex z-10 shadow-lg ${isRendered ? 'absolute' : 'relative'}`}>
      <div className="bg-white relative container max-w-7xl flex flex-col content-center justify-between mx-auto py-10 px-6 md:px-10">
        {Array.isArray(navigation) &&
          navigation?.map((nav, idx) => {
            const hasItems = Array.isArray(nav?.items) && nav?.items?.length > 0

            const col1 = []
            const col2 = []
            const col3 = []

            let colId = 1
            // eslint-disable-next-line no-unused-expressions
            nav?.items?.forEach((item) => {
              if (colId > 3) {
                colId = 1
              }
              if (colId === 1) {
                col1.push(item)
              } else if (colId === 2) {
                col2.push(item)
              } else if (colId === 3) {
                col3.push(item)
              }
              colId += 1
            })

            return hasItems && (selectedNavItem === nav?.id || !isRendered) ? (
              <ul key={`menu-nav-${nav.id}-${idx}`} className="list-none p-0 m-0 grid grid-cols-1 lg:grid-cols-3 gap-8">
                {[col1, col2, col3].map((col, i) => (
                  <div key={`col_desktop${i}`}>
                    {col.map((item, idx2) => (
                      <li key={`menu-desktop-item-${item?.id}-${idx}-${idx2}`} className="mb-8">
                        <TertiaryStyle to={item?.url} hasIcon isSection>
                          <div className="block font-medium mb-2">{item?.label}</div>
                        </TertiaryStyle>
                        {Array.isArray(item?.items) && item?.items?.length > 0 && (
                          <div>
                            <ul className="list-none p-0 m-0">
                              {item?.items?.map((subitem, idx3) => (
                                <li key={`block menu-item-${item?.id}-${idx}-subitem-${subitem?.id}-${idx3}`}>
                                  <TertiaryStyle to={subitem?.url} hasIcon>
                                    <div className="block">{subitem?.label}</div>
                                  </TertiaryStyle>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </li>
                    ))}
                  </div>
                ))}
              </ul>
            ) : null
          })}
      </div>
    </div>
  )
}

export const MenuContentsMobile = ({
  navigation,
  extra,
  onCloseNav = () => {
    /* noop */
  }
}) => {
  const [expandedNavItem, setExpandedNavItem] = useState(-1)
  const mobileNavItems = [...(Array.isArray(navigation) ? navigation : []), ...(Array.isArray(extra) ? extra : [])]

  const [isRendered, setIsRendered] = useState(false)
  useEffect(() => {
    setIsRendered(true)
  }, [])

  const toggleAccordion = (id) => {
    setExpandedNavItem(id === expandedNavItem ? -1 : id)
  }

  const cols = [
    mobileNavItems.slice(0, Math.ceil(mobileNavItems.length / 2)),
    mobileNavItems.slice(Math.ceil(mobileNavItems.length / 2))
  ]

  return (
    <div id="mobile-menu" className="bg-white w-full flex relative">
      <div className="bg-white relative container max-w-7xl flex flex-col content-center justify-between mx-auto pt-2 pb-10 md:pt-8 md:pb-16 lg:pt-16 lg:pb-16 lg:pb-20 px-6 md:px-10">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8 gap-y-0">
          {Array.isArray(mobileNavItems) &&
            cols.map((col, i) => {
              return (
                <div key={`col_mobile_${i}`}>
                  {col?.map((nav, j) => {
                    const hasItems = Array.isArray(nav?.items) && nav?.items?.length > 0
                    const isLastItem = i === cols?.length - 1 && j === col?.length - 1

                    return (
                      <AccordionItemWrapper key={`menu-mobile-nav-${nav?.id}`} expanded={expandedNavItem === nav?.id}>
                        <AccordionItem
                          hasItems={hasItems}
                          key={nav?.id}
                          to={nav?.url}
                          active={expandedNavItem === nav?.id || !isRendered}
                          onClick={(e) => {
                            if (hasItems) {
                              e.preventDefault()
                              toggleAccordion(nav?.id)
                            }
                          }}
                          onFocus={() => {
                            if (hasItems) {
                              toggleAccordion(nav?.id)
                            }
                          }}
                          onBlur={
                            isLastItem
                              ? () => {
                                  onCloseNav()
                                }
                              : undefined
                          }
                        >
                          {Array.isArray(nav?.items) && nav?.items?.length > 0 ? (
                            <>
                              <span className="flex items-center">{nav?.label}</span>
                              {expandedNavItem === nav?.id || !isRendered ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
                            </>
                          ) : (
                            <>
                              <span className="flex items-center">{nav?.label}</span>
                            </>
                          )}
                        </AccordionItem>
                        {hasItems && (expandedNavItem === nav?.id || !isRendered) && (
                          <ul className="list-none p-0 m-0 pt-4">
                            {nav?.items?.map((item, k) => {
                              const isLastSub = k === nav?.items.length - 1 && isLastItem

                              return (
                                <li key={`menu-item-${item?.id}`}>
                                  <TertiaryStyle to={item?.url} hasIcon isSection>
                                    <div className="block">{item?.label}</div>
                                  </TertiaryStyle>
                                  {Array.isArray(item?.items) && item?.items?.length > 0 && (
                                    <div>
                                      <ul className="list-none px-0 py-1 m-0">
                                        {item?.items?.map((subitem) => (
                                          <li key={`block menu-item-${item?.id}-subitem-${subitem?.id}`}>
                                            <TertiaryStyle
                                              to={subitem?.url}
                                              hasIcon
                                              onBlur={isLastSub ? () => onCloseNav() : undefined}
                                            >
                                              <div className="block">{subitem?.label}</div>
                                            </TertiaryStyle>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                </li>
                              )
                            })}
                          </ul>
                        )}
                      </AccordionItemWrapper>
                    )
                  })}
                </div>
              )
            })}
        </div>
      </div>
      <button
        type="button"
        onFocus={onCloseNav}
        onClick={() => {
          /* noop */
        }}
      />
    </div>
  )
}
