import React from 'react'
import useCookie from 'react-use-cookie'
import { useLocation } from '@reach/router'
//
import { gtmPushEvent } from '~utils/gtm'
import GDPR from '~storybook/gdpr'

const Cookies = ({ fieldGdprTitle, fieldGdprDescription, fieldGdprCookies }) => {
  if (!fieldGdprCookies?.length) {
    return null
  }

  const location = useLocation()

  const [consents, setConsents] = useCookie('cookieConsent', null)

  if (consents) {
    return null
  }

  const cookiesOptions = fieldGdprCookies
    .map((category) => category.entity)
    .map((entity) => {
      return {
        id: entity?.tid,
        label: entity?.name,
        name: entity?.name?.replace(' ', '').toLowerCase(),
        required: entity?.fieldCookieDefault
      }
    })

  const handleSubmit = (options) => {
    const newOptions = {}
    options.forEach((option) => {
      newOptions[option.name] = option?.checked ? 1 : 0
    })
    setConsents(JSON.stringify(newOptions), { days: 31 })
    gtmPushEvent('gatsby-cookies-accepted', { location })
  }

  return (
    <GDPR title={fieldGdprTitle} text={fieldGdprDescription} cookiesOptions={cookiesOptions} onSubmit={handleSubmit} />
  )
}

export default Cookies
