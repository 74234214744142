import React from 'react'
import tw, { styled } from 'twin.macro'
//
import Icons from '~storybook/icons'
import NavLink from '~utils/navlink'

const DefaultButton = styled(({ children, hasIcon, to = null, ...other }) =>
  to ? (
    <NavLink to={to} {...other}>
      {children}
    </NavLink>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <button type="button" {...other}>
      {children}
    </button>
  )
)`
  ${tw`text-white font-medium py-3 px-4 rounded-sm transition-colors duration-200 flex justify-center min-w-button`}
  ${({ to }) => to && tw`inline-flex cursor-pointer no-underline hover:no-underline hover:text-white`}
  ${({ hasIcon }) => hasIcon && tw`pr-4 pl-6 justify-between`}
`

const DarkButton = styled(DefaultButton)`
  ${tw`bg-c-dark-button-bg text-c-dark-button-text border border-solid border-c-dark-button-border hover:border-c-dark-button-hoverBorder hover:bg-c-dark-button-hoverBg hover:text-c-dark-button-hoverText focus:text-c-dark-button-focusText focus:bg-c-dark-button-focusBg active:bg-c-dark-button-activeBg active:border active:border-solid active:border-c-dark-button-activeBorder focus:outline-none`}
  ${({ disabled }) => disabled && tw`opacity-40 cursor-default hover:bg-c-dark-button-hoverText`}
`

const PrimaryButton = styled(DefaultButton)`
  ${tw`bg-c-primary-button-bg hover:bg-c-primary-button-hover focus:bg-c-primary-button-focus active:bg-c-primary-button-active text-c-primary-button-text focus:outline-none`}
  ${({ disabled }) => disabled && tw`opacity-40 cursor-default hover:bg-c-primary-button-disabled`}
`

const SecondaryButton = styled(DefaultButton)`
  ${tw`bg-c-secondary-button-bg text-c-secondary-button-text border border-solid border-c-secondary-button-border hover:border-c-secondary-button-hover hover:bg-c-secondary-button-hover focus:bg-c-secondary-button-focus active:bg-c-secondary-button-active active:border active:border-solid active:border-c-secondary-button-activeBorder focus:outline-none`}
  ${({ disabled }) =>
    disabled &&
    tw`opacity-40 cursor-default hover:text-c-secondary-button-disabledHoverText hover:bg-c-secondary-button-disabledHoverBg hover:border hover:border-solid hover:border-c-secondary-button-disabledHoverBorder`}
`

const TertiaryButton = styled(DefaultButton)`
  ${tw`bg-c-tertiary-button-bg text-c-tertiary-button-text p-0 hover:bg-c-tertiary-button-bg hover:text-c-tertiary-button-hoverText hover:underline min-w-0`}
  ${({ disabled }) => disabled && tw`opacity-40 hover:text-c-tertiary-button-disbaledHoverText cursor-default`}
`

const variants = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
  dark: DarkButton
}

export const Button = ({
  children,
  to = null,
  variant = 'primary',
  disabled = false,
  icon = 'ArrowRight',
  ...rest
}) => {
  const ButtonComp = variants[variant] || variants.primary
  const IconComp = (icon && Icons[icon]) || null
  const hasIcon = IconComp || null

  return (
    <ButtonComp to={to} hasIcon={hasIcon} disabled={disabled} {...rest}>
      <span>{children}</span>
      {IconComp && <IconComp className="ml-2" />}
    </ButtonComp>
  )
}
