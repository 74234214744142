import React from 'react'
import { styled, theme } from 'twin.macro'
//
import Icons from '~storybook/icons/index'
import NavLink from '~utils/navlink'

const iconsMapping = {
  facebook: () => <Icons.Facebook />,
  twitter: () => <Icons.Twitter />,
  instagram: () => <Icons.Instagram />,
  linkedin: () => <Icons.LinkedIn />
}

const SocialLinkHeroItem = ({ id, url, label }) => {
  const Icon = iconsMapping?.[id]

  if (!Icon || !url || !label) {
    return null
  }

  const Container = styled.div`
    svg {
      width: 24px;
      height: 24px;
      fill: ${theme`colors['c-social-links'].heroFill`} !important;
    }
  `

  return (
    <Container className="ml-0 mr-4 md:ml-3 md:mr-2 self-center">
      <NavLink aria-label={label} to={url}>
        <Icon />
      </NavLink>
    </Container>
  )
}

const SocialLinkItem = ({ id, url, label }) => {
  const Icon = iconsMapping?.[id]

  if (!Icon || !url || !label) {
    return null
  }

  return (
    <div className="ml-2 mr-2">
      <NavLink aria-label={label} to={url}>
        <Icon />
      </NavLink>
    </div>
  )
}

export const SocialLinks = ({ links = {}, dark = false, hero = false, header = false }) => {
  const SocialLinkWrapper = styled.div`
    width: 100%;
    color: ${theme`colors['c-social-links'].text`};

    svg {
      fill: ${theme`colors['c-social-links'].darkFill`} !important;
    }

    &.dark {
      background: ${theme`colors['c-social-links'].bg`};
      svg {
        fill: ${theme`colors['c-social-links'].lightFill`} !important;
      }
    }

    .externalLink {
      cursor: pointer;
      padding: 0.5rem 1rem;
      svg {
        fill: ${theme`colors['c-social-links'].lightFill`};
      }
    }

    .hidden {
      display: none !important;
    }

    .active {
      display: flex !important;
    }
  `

  if (hero) {
    return (
      <SocialLinkWrapper className="flex mt-4 md:mt-0 ml-1 md:ml-16">
        {Object.keys(links)?.map((key, idx) => (
          <SocialLinkHeroItem key={`socialheroitem-${key}-${idx}`} id={key} {...links[key]} />
        ))}
      </SocialLinkWrapper>
    )
  }

  if (header) {
    return (
      <SocialLinkWrapper className="flex">
        {Object.keys(links)?.map((key, idx) => (
          <SocialLinkItem key={`socialitem-header-${key}-${idx}`} id={key} {...links[key]} />
        ))}
      </SocialLinkWrapper>
    )
  }

  return (
    <SocialLinkWrapper className={dark ? 'p-5 flex justify-center dark' : 'p-5 flex flex-row justify-center'}>
      {Object.keys(links)?.map((key, idx) => (
        <SocialLinkItem key={`socialitem-${key}-${idx}`} id={key} {...links[key]} />
      ))}
    </SocialLinkWrapper>
  )
}

export default SocialLinks
