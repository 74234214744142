import React from 'react'
import tw, { theme, styled } from 'twin.macro'
//
import { Html } from '~storybook/blocks/index'
import { isSSR } from '~utils/ssr'

const StyledTable = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow-x: auto;
  width: 100%;

  caption {
    text-indent: -99999px;
    height: 0px;
    width: 0px;
  }

  table {
    width: 100%;
    min-width: 100%;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }
  .table-wrap {
    position: relative;
  }

  th,
  td {
    ${tw`py-3 px-2`}
    border: 0;
    vertical-align: top;
  }
  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  /* safari and ios need the tfoot itself to be position:sticky also */
  tfoot,
  tfoot th,
  tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index:4;
  }

  a {
    ${tw`text-white font-thin py-0 cursor-pointer transition-colors duration-200`}
    ${tw`bg-transparent text-c-table-link hover:text-c-table-linkHover hover:underline`}
    line-height: 26px;
  }

  th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    max-width: 150px;

    ${({ boldFirstCol }) => boldFirstCol && tw`font-bold`}
    text-align: left;

    @media (min-width: ${theme`screens.md`}) {
      max-width: 200px;
    }

    @media (min-width: ${theme`screens.lg`}) {
      max-width: auto;
    }
  }
  thead th:first-child,
  tfoot th:first-child {
    z-index: 5;
  }

  tr:first-child {
    th,
    td {
      ${({ boldFirstRow }) => boldFirstRow && tw`font-bold`}
    }
  }
`

const renderTable = (props) => {

  if (!isSSR) {

    // open the link of a table row, if the link has class 'row_link'
    window.addEventListener("click", (e) => {
      var clickedNode = e.target
      var parentClickedNode = clickedNode.parentNode
      if(parentClickedNode.tagName == 'TR') {
        var rowlink = parentClickedNode.querySelector('a.row_link')
        if(rowlink) {
          window.location = rowlink.href
        }
      }
    });

    // if hover over a <tr>, if it contains a data-image attribute, update the src of the img with the class 'table_image'
    window.addEventListener("mouseover", (e) => {
      var clickedNode = e.target
      var parentClickedNode = clickedNode.parentNode
      if(parentClickedNode.tagName == 'TR') {
        var img_src = parentClickedNode.getAttribute('data-image')
        if(img_src) {
          var table_image = document.querySelector('.table_image img')
          table_image.src = img_src
        }
      }
    });
  }

    return <table {...props} />
}
const renderTR = (props) => {
  return <tr {...props} />
}
const renderTD = (props) => {
  return <td {...props} />
}
const renderTH = (props) => {
  return <th {...props} />
}
const renderTHead = (props) => {
  return <thead {...props} />
}
const renderTBody = (props) => {
  return <tbody {...props} />
}

const transform = {
  table: (node, props) => renderTable({ ...node, ...props }),
  tr: (node, props) => {
    const trContents = []
    if (node?.children) {
      /* eslint-disable no-unused-expressions, array-callback-return */
      node?.children?.map((child, idx) => {
        if (child?.type === 'th') {
          trContents.push(renderTH({ ...child?.props, className: '', type: 'th', key: `tr-${idx}` }))
        } else if (child?.type === 'td') {
          trContents.push(renderTD({ ...child?.props, className: '', type: 'td', key: `td-${idx}` }))
        }
      })
      /* eslint-enable no-unused-expressions, array-callback-return */
    }
    return renderTR({ ...props, ...node, children: trContents })
  },
  thead: (node, props) => renderTHead({ ...node, ...props }),
  tbody: (node, props) => renderTBody({ ...node, ...props })
}

export const tableTransform = {
  table: (node, props) => <StyledTable zebra>{renderTable({ ...node, ...props })}</StyledTable>,
  tr: (node, props) => {
    const trContents = []
    if (node?.children) {
      /* eslint-disable no-unused-expressions, array-callback-return */
      node?.children?.map((child, idx) => {
        if (child?.type === 'th') {
          trContents.push(renderTH({ ...child?.props, type: 'th', key: `tr-${idx}` }))
        } else if (child?.type === 'td') {
          trContents.push(renderTD({ ...child?.props, type: 'td', key: `td-${idx}` }))
        }
      })
      /* eslint-enable no-unused-expressions, array-callback-return */
    }
    return renderTR({ ...props, ...node, children: trContents })
  },
  thead: (node, props) => renderTHead({ ...node, ...props }),
  tbody: (node, props) => renderTBody({ ...node, ...props })
}

export const Table = ({ html, zebra = false, boldFirstRow = false, boldFirstCol = false }) => {
  return (
    <StyledTable zebra={zebra} boldFirstCol={boldFirstCol} boldFirstRow={boldFirstRow}>
      <Html value={html} transform={transform} />
    </StyledTable>
  )
}

export default Table
