import React from 'react'
import tw, { styled } from 'twin.macro'
//

const StyledLabel = styled.label(({ disabled }) => [
  tw`block text-c-input-label-label mb-2`,
  disabled && tw`opacity-40`
])

export const InputLabel = ({ text, htmlFor, disabled = false }) => (
  <StyledLabel htmlFor={htmlFor} disabled={disabled}>
    {text}
  </StyledLabel>
)

export default InputLabel
