import React, { useEffect } from 'react'
import tw, { styled, css } from 'twin.macro'
import FormattedText from '~utils/text'
//

const InputLabel = styled.label(({ disabled }) => [tw`text-c-textarea-text mb-2`, disabled && tw`opacity-40`])

const Textarea = styled.textarea(({ haserror, disabled }) => [
  css`
    ::-webkit-input-placeholder {
      font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }
  `,

  tw`py-2 px-4 text-sm text-c-textarea-text border border-solid border-c-textarea-border placeholder-c-textarea-placeholder rounded-sm outline-none`,
  tw`transform transition-all duration-200`,

  tw`hover:(border-c-textarea-borderHover)`,
  tw`focus:(border-c-textarea-borderFocus)`,

  haserror && tw`placeholder-c-textarea-error border-c-textarea-error text-c-textarea-error`,
  disabled && tw`opacity-40`
])

const ErrorMessage = styled.div`
  ${tw`text-xs text-c-textarea-error pt-1`}
`

export const TextareaField = ({
  field = {},
  label = '',
  haserror = false,
  disabled = false,
  focus = false,
  tabIndex,
  onChange = () => {
    /* noop */
  },
  onBlur = () => {
    /* noop */
  },
  onInitialRender = () => {
    /* noop */
  }
}) => {
  const ref = React.useRef()
  useEffect(() => {
    onInitialRender()
  }, [])

  React.useEffect(() => {
    if (focus && ref?.current) {
      ref.current.focus()
    }
  }, [focus, ref])

  const onChangeHandler = (e) => {
    onChange(e.target.value)
  }

  const moveCaretAtEnd = (e) => {
    e.target.setSelectionRange(e.target.value.length, e.target.value.length)
  }

  return (
    <div className="flex flex-col flex-wrap" tabIndex={tabIndex || undefined} onFocus={() => ref?.current?.focus()}>
      {label && (
        <InputLabel htmlFor={field.name} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <Textarea
        ref={ref}
        autoFocus={focus}
        onFocus={moveCaretAtEnd}
        id={field.name}
        haserror={haserror}
        disabled={disabled}
        placeholder={field.placeholder}
        defaultValue={field.value}
        autoComplete="off"
        onChange={onChangeHandler}
        onBlur={onBlur}
      />
      {haserror && field.error && (
        <ErrorMessage>
          <FormattedText format="html" text={field.error} />
        </ErrorMessage>
      )}
    </div>
  )
}
