import React, { useEffect } from 'react'
import tw, { styled, css } from 'twin.macro'
import FormattedText from '~utils/text'
//

const InputLabel = styled.label(({ disabled }) => [tw`text-c-input-text mb-2`, disabled && tw`opacity-40`])

const TextInput = styled.input(({ haserror, disabled, search = false }) => [
  css`
    ::-webkit-input-placeholder {
      font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }
  `,
  tw`w-full block min-h-input cursor-text py-2 px-4 text-lg text-c-input-text border border-solid border-c-input-border placeholder-c-input-placeholderColour outline-none`,
  tw`transform transition-all duration-200`,

  tw`hover:(border-c-input-borderHover)`,
  tw`focus:(border-c-input-borderFocus)`,

  search ? tw`rounded-l-sm` : `rounded-sm`,

  haserror && tw`text-c-input-error border-c-input-error`,
  disabled && tw`opacity-40 cursor-not-allowed`
])

const ErrorMessage = styled.div`
  ${tw`text-xs text-c-input-error pt-1`}
`

export const InputField = ({
  field = {},
  label = '',
  haserror = false,
  disabled = false,
  focus = false,
  tabIndex,
  search = false,
  onKeyDown = () => {
    /* noop */
  },
  onChange = () => {
    /* noop */
  },
  onBlur = () => {
    /* noop */
  },
  onInitialRender = () => {
    /* noop */
  }
}) => {
  const ref = React.useRef()

  const onChangeHandler = (e) => {
    onChange(e.target.value)
  }

  React.useEffect(() => {
    if (focus && ref?.current) {
      ref.current.focus()
    }
  }, [focus, ref])

  useEffect(() => {
    onInitialRender()
  }, [])

  return (
    <div className="flex flex-col flex-wrap" tabIndex={tabIndex || undefined} onFocus={() => ref?.current?.focus()}>
      {label && (
        <InputLabel htmlFor={field.name} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <TextInput
        ref={ref}
        id={field.name}
        name={field.name}
        type={field.type}
        haserror={haserror}
        disabled={disabled}
        placeholder={field.placeholder}
        defaultValue={field.value}
        autoComplete="off"
        search={search}
        onChange={onChangeHandler}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
      {haserror && field.error && (
        <ErrorMessage>
          <FormattedText format="html" text={field.error} />
        </ErrorMessage>
      )}
    </div>
  )
}
