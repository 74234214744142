import React, { useCallback, useEffect, useReducer, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { debounce } from 'lodash'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'
//
import { Heading, Html } from '~storybook/blocks'
import Button from '~storybook/button'
import InputLabel from '~storybook/form/input-label'
import InputField from '~storybook/form/input'
import TextareaField from '~storybook/form/textarea'
import InputOptionGroup from '~storybook/form/input-option'
import InputSelect from '~storybook/form/input-select'
import FilesInput from '~storybook/form/files'
import CalendarInput from '~storybook/form/calendar'
import TimeSelect from '~storybook/form/time-select'
import { Disclaimer } from '../typography/index'
import reducer, { onValidateField } from './form-reducer'
import FormattedText from '~utils/text'

const StyledForm = styled.form`
  ${tw`outline-none`}
  > div {
    ${tw`mt-2 mb-4`}
  }
  .webform-element-more,
  .webform-element-help {
    display: none;
  }
  .description,
  .webform-element-description {
    ${tw`mt-0 mb-6 font-normal text-xs`}
  }
  .form-type-processed-text {
    ${tw`mt-6 mb-6`}
  }
  .form-type-webform-markup {
    ${tw`mt-6 mb-6`}
  }
  .form-item.form-type--radio,
  .form-checkboxes .form-item.form-type--checkbox {
    &:not(:last-child) {
      .error-message {
        display: none !important;
      }
    }
  }
`
const ErrorMessage = styled.div`
  ${tw`text-xs text-c-form-error pt-1`}
`

export const Form = ({
  isSubmitting = false,
  values = false,
  errors = false,
  entityRendered,
  fieldForm = { entity: { entityLabel: '' } },
  maxUploadSize,
  onSubmit = () => {
    /* noop */
  },
  onUpload = () => {
    /* noop */
  }
}) => {
  const [state, dispatch] = useReducer(reducer, { formValid: false, initialized: false, formData: {}, formState: {} })

  const intl = useIntl()
  const { entity } = fieldForm
  const { entityLabel } = entity
  const { initialized, formData, formState, formValid } = state
  const [formStateToInitialize, setFormStateToInitialize] = useState([])
  const [focus, setFocus] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [formKeys, setFormKeys] = useState([])

  const debouncedSetFormState = useCallback(
    debounce(
      () => {
        dispatch({ type: 'set_form_initialized' })
      },
      100,
      { leading: false, trailing: true }
    ),
    [state]
  )

  useEffect(() => {
    if (initialized && (errors || values)) {
      dispatch({ type: 'validate_all_fields' })
      dispatch({ type: 'prepopulate_form', payload: { errors: errors || false, values: values || false } })
      // scroll to first error field
      setTimeout(() => {
        const el = document.getElementsByClassName('error')?.[0]
        if (el) {
          const rect = el.getBoundingClientRect()
          window.scrollTo({
            top: rect.top + window.scrollY - 50,
            behavior: 'smooth'
          })
        }
      }, 500)
    }
  }, [errors, values, initialized])

  useEffect(() => {
    dispatch({ type: 'set_intl', payload: { intl } })
  }, [intl])

  useEffect(() => {
    dispatch({ type: 'set_form_state', payload: { formState: formStateToInitialize } })
    debouncedSetFormState()
  }, [formStateToInitialize])

  const onFormDataChange = ({ key, value, type, required }) => {
    dispatch({ type: 'update_field', payload: { key, value, type, required } })
    dispatch({ type: 'validate_single_field', payload: { key } })
    setFocus(key)
  }

  const initializeFormState = (key, obj) => {
    if (!formStateToInitialize[key]) {
      setFormStateToInitialize({ ...formStateToInitialize, [key]: obj })
    }
  }

  const onFormSubmit = () => {
    const newFormState = { ...state.formState }

    let checkFormValid = true
    Object.keys(newFormState).forEach((key) => {
      const fieldValidation = onValidateField({
        state,
        formData: state?.formData || {},
        key: key?.split('[')?.[0],
        type: newFormState[key?.split('[')?.[0]]?.type,
        required: newFormState[key?.split('[')?.[0]]?.required
      })
      if (!fieldValidation?.valid) {
        checkFormValid = false
      }
      newFormState[key] = { ...newFormState[key], ...fieldValidation }
    })
    dispatch({ type: 'validate_all_fields' })
    if (checkFormValid) {
      const preparedObj = {}
      Object.keys(formData).forEach((key) => {
        const keyHasBrackets = /^.{1,}[[].{1,}[\]]$/gim.test(key)
        if (keyHasBrackets) {
          const keyToUse = key?.split('[')?.[0]
          const valueToUse = key?.split('[')?.[1]?.replace(']', '')
          if (formData[key]) {
            preparedObj[keyToUse] = valueToUse
          }
        } else if (formData?.[key]?.dateValue) {
          preparedObj[key] = format(formData?.[key]?.dateValue, 'yyyy-MM-dd')
        } else if (formData?.[key]?.timeValue) {
          preparedObj[key] = `${formData?.[key]?.timeValue}:00`
        } else if (formData?.[key]?.fids) {
          const name = key.replace('files.', '')
          preparedObj[name] = formData?.[key]?.fids
        } else if (
          typeof formData?.[key] === 'object' &&
          formData?.[key] !== null &&
          Array.isArray(formData?.[key]) === false
        ) {
          preparedObj[key] = formData?.[key]?.label
        } else {
          preparedObj[key] = formData?.[key]
        }
      })

      onSubmit(preparedObj, formData)
    }
    if (typeof window !== 'undefined') {
      // scroll to first error field
      setTimeout(() => {
        const el = document.getElementsByClassName('error')?.[0]
        if (el) {
          const rect = el.getBoundingClientRect()
          window.scrollTo({
            top: rect.top + window.scrollY - 50,
            behavior: 'smooth'
          })
        }
      }, 500)
    }
    return setSubmitted(true)
  }

  const renderForm = ({ children, acceptCharset }) => {
    return (
      // eslint-disable-next-line no-console
      <StyledForm
        acceptCharset={acceptCharset}
        onSubmit={(e) => {
          e.preventDefault()
          onFormSubmit()
        }}
      >
        {children}
      </StyledForm>
    )
  }

  const renderLabel = (props) => {
    const { htmlFor, className, children } = props
    return className?.includes('option') ? null : <InputLabel text={children?.[0]} htmlFor={htmlFor} />
  }

  const renderFile = (props) => {
    const { id, name: origName, label, type, description, multiple, required } = props

    const name = origName.replace('[', '.').replace(']', '').replace('[]', '')

    const isUploading = formData?.[name]?.isUploading || false
    const isUploaded = formData?.[name]?.isUploaded || false
    const uploadFiles = formData?.[name]?.files || []

    return (
      <div className={`my-3 ${!formState?.[name]?.valid && (formState?.[name]?.blur || submitted) ? 'error' : ''}`}>
        <FilesInput
          field={{
            id,
            name: name.replace('files.', ''),
            type,
            multiple,
            description,
            error: formState?.[name]?.error,
            ...formData?.[name]
          }}
          maxUploadSize={maxUploadSize}
          isUploading={isUploading || false}
          isUploaded={isUploaded || false}
          hasError={!formState?.[name]?.valid && (formState?.[name]?.blur || submitted) && !isUploading}
          files={uploadFiles || []}
          label={label}
          onReset={() => {
            onFormDataChange({
              key: name,
              type,
              value: { files: [], fids: [], isUploading: false, isUploaded: false, blur: true },
              required
            })
          }}
          onUpload={async (files) => {
            dispatch({ type: 'blur_field', payload: { key: name } })
            onFormDataChange({
              key: name,
              type,
              value: { files, isUploading: true, isUploaded: false, blur: true },
              required
            })
            const res = await onUpload(name.replace('files.', ''), files)
            const fids = res?.map((result) => result?.fid?.[0]?.value)?.filter((fid) => fid) || []
            if (fids?.length) {
              onFormDataChange({
                key: name,
                type,
                value: { files, fids, isUploading: false, isUploaded: true, blur: true },
                required
              })
            } else {
              onFormDataChange({
                key: name,
                type,
                value: { files, fids: [], isUploading: true, isUploaded: false, blur: true },
                required
              })
            }
          }}
          onInitialRender={() => {
            // initialize formState with every field
            if (!formKeys.includes(name)) {
              setFormKeys([...formKeys, name])
            }
            if (!formStateToInitialize[name]) {
              initializeFormState(name, { validated: false, valid: false, blur: false, type, required })
            }
          }}
        />
      </div>
    )
  }

  const renderInput = (props) => {
    const {
      id,
      name,
      type,
      value,
      required,
      'aria-describedby': ariaDescribedBy,
      'data-drupal-selector': dataDrupalSelector
    } = props
    const tabIndex = [...formKeys].reverse().indexOf(name)

    if (type === 'submit' && dataDrupalSelector === 'edit-document-upload-button') {
      return null
    }

    if (type === 'submit') {
      return (
        <div className="my-3">
          <Button
            id={id}
            type="submit"
            icon=""
            disabled={isSubmitting}
            onClick={(e) => {
              e.preventDefault()
              onFormSubmit()
            }}
          >
            {isSubmitting ? intl?.formatMessage({ id: 'form.submitting' }) : value}
          </Button>
          {(errors?.message || submitted) && !formValid && (
            <ErrorMessage>
              <FormattedText
                format="html"
                text={errors?.message || intl?.formatMessage({ id: 'form.formValidation' })}
              />
            </ErrorMessage>
          )}
        </div>
      )
    }

    if (typeof name !== 'string') {
      return null
    }

    if (type === 'text' || type === 'number' || type === 'tel' || type === 'url' || type === 'email') {
      return (
        <div className={`my-3 ${!formState?.[name]?.valid && (formState?.[name]?.blur || submitted) ? 'error' : ''}`}>
          <InputField
            id={id}
            field={{ name, type: 'text', value: formData?.[name] || '', error: formState?.[name]?.error }}
            type="text"
            onChange={(val) => onFormDataChange({ key: name, type, required, value: val })}
            focus={focus && name === focus}
            // onBlur={() => onFormDataBlur({ key: name })}
            haserror={!formState?.[name]?.valid && (formState?.[name]?.blur || submitted)}
            onInitialRender={() => {
              // initialize formState with every field
              if (!formKeys.includes(name)) {
                setFormKeys([...formKeys, name])
              }
              if (!formStateToInitialize[name]) {
                initializeFormState(name, { validated: false, valid: false, blur: false, type, required })
              }
            }}
            tabIndex={tabIndex > -1 && tabIndex + 1}
          />
        </div>
      )
    }

    if (type === 'textarea') {
      return (
        <div className={`my-3 ${!formState?.[name]?.valid && (formState?.[name]?.blur || submitted) ? 'error' : ''}`}>
          <TextareaField
            id={id}
            field={{ name, value: formData?.[name] || '', error: formState?.[name]?.error }}
            onChange={(val) => onFormDataChange({ key: name, type, required, value: val })}
            focus={focus && name === focus}
            // onBlur={() => onFormDataBlur({ key: name })}
            haserror={!formState?.[name]?.valid && (formState?.[name]?.blur || submitted)}
            onInitialRender={() => {
              // initialize formState with every field
              if (!formKeys.includes(name)) {
                setFormKeys([...formKeys, name])
              }
              if (!formStateToInitialize[name]) {
                initializeFormState(name, { validated: false, valid: false, blur: false, type, required })
              }
            }}
            tabIndex={tabIndex > -1 && tabIndex + 1}
          />
        </div>
      )
    }

    if (type === 'checkbox') {
      const label = props?.label || props?.value || ''
      const error = props?.['data-webform-required-error'] || formState?.[name?.split('[')?.[0]]?.error

      return (
        <div
          className={`my-3 ${
            !formState?.[name?.split('[')?.[0]]?.valid && (formState?.[name?.split('[')?.[0]]?.blur || submitted)
              ? 'error'
              : ''
          }`}
        >
          <InputOptionGroup
            id={id}
            label={null}
            field={{ name, error }}
            options={[{ name, label, checked: formData?.[name] || false }]}
            radios={type === 'radio'}
            focus={focus && name === focus}
            onUpdate={(options) => {
              // onFormDataBlur({ key: name })
              onFormDataChange({ key: name, type, required, value: options[0].checked })
            }}
            haserror={
              !formState?.[name?.split('[')?.[0]]?.valid && (formState?.[name?.split('[')?.[0]]?.blur || submitted)
            }
            onInitialRender={() => {
              // initialize formState with every field
              if (!formKeys.includes(name)) {
                setFormKeys([...formKeys, name])
              }
              if (!formStateToInitialize[name]) {
                initializeFormState(name?.split('[')?.[0], {
                  validated: false,
                  valid: false,
                  blur: false,
                  type,
                  required
                })
              }
            }}
            tabIndex={tabIndex > -1 && tabIndex + 1}
          />
          {props?.disclaimer && <Disclaimer>{props?.disclaimer}</Disclaimer>}
        </div>
      )
    }

    if (type === 'radio') {
      const label = props?.value

      return (
        <div className={`my-3 ${!formState?.[name]?.valid && (formState?.[name]?.blur || submitted) ? 'error' : ''}`}>
          <InputOptionGroup
            id={id}
            label={null}
            focus={focus && name === focus}
            field={{ id, name, error: formState?.[name]?.error, ariaDescribedBy }}
            options={[{ name, label, checked: formData?.[name]?.label === label || false }]}
            radios={type === 'radio'}
            onUpdate={(options) => {
              // onFormDataBlur({ key: name })
              onFormDataChange({ key: name, type, required, value: options[0] })
            }}
            onInitialRender={() => {
              // initialize formState with every field
              if (!formKeys.includes(name)) {
                setFormKeys([...formKeys, name])
              }
              if (!formStateToInitialize[name]) {
                initializeFormState(name, { validated: false, valid: false, blur: false, type, required })
              }
            }}
            haserror={!formState?.[name]?.valid && (formState?.[name]?.blur || submitted)}
          />
        </div>
      )
    }

    if (type === 'date') {
      return (
        <div
          className={`my-3 max-w-full md:max-w-md ${
            !formState?.[name]?.valid && (formState?.[name]?.blur || submitted) ? 'error' : ''
          }`}
        >
          <CalendarInput
            id={id}
            label={props?.label}
            dates={{ startDate: formData?.[name]?.dateValue }}
            handleChange={(date) => {
              onFormDataChange({ key: name, type, required, value: { dateValue: date?.startDate } })
            }}
            haserror={!formState?.[name]?.valid && (formState?.[name]?.blur || submitted)}
            error={formState?.[name]?.error || ''}
            onInitialRender={() => {
              // initialize formState with every field
              if (!formKeys.includes(name)) {
                setFormKeys([...formKeys, name])
              }
              if (!formStateToInitialize[name]) {
                initializeFormState(name, { validated: false, valid: false, blur: false, type, required })
              }
            }}
          />
        </div>
      )
    }

    if (type === 'time') {
      return (
        <div className={`my-3 ${!formState?.[name]?.valid && (formState?.[name]?.blur || submitted) ? 'error' : ''}`}>
          <TimeSelect
            id={id}
            label={props?.label}
            value={formData?.[name]?.timeValue}
            field={{ error: formState?.[name]?.error }}
            onSelect={(time) => {
              onFormDataChange({ key: name, type, required, value: { timeValue: time } })
            }}
            haserror={!formState?.[name]?.valid && (formState?.[name]?.blur || submitted)}
            onInitialRender={() => {
              // initialize formState with every field
              if (!formKeys.includes(name)) {
                setFormKeys([...formKeys, name])
              }
              if (!formStateToInitialize[name]) {
                initializeFormState(name, { validated: false, valid: false, blur: false, type, required })
              }
            }}
            tabIndex={tabIndex > -1 && tabIndex + 1}
          />
        </div>
      )
    }

    return null
  }

  const renderSelect = ({ children, className, id, name, required }) => {
    if (typeof name !== 'string') {
      return null
    }

    const options = children.map((child) => {
      return {
        value: typeof child.props.value === 'string' ? child.props.children[0] : '',
        label:
          typeof child.props.value === 'string'
            ? child.props.children[0]
            : intl?.formatMessage({ id: 'form.emptySelect' })
      }
    })

    return (
      <div className={`my-3 ${!formState?.[name]?.valid && (formState?.[name]?.blur || submitted) ? 'error' : ''}`}>
        <InputSelect
          options={options}
          field={{ name, error: formState?.[name]?.error }}
          value={formData?.[name] || ''}
          onSelect={(sel) => {
            // onFormDataBlur({ key: name })
            onFormDataChange({ key: name, type: 'select', required, value: sel?.value })
          }}
          // onBlur={() => onFormDataBlur({ key: name })}
          haserror={!formState?.[name]?.valid && (formState?.[name]?.blur || submitted)}
          onInitialRender={() => {
            // initialize formState with every field
            if (!formKeys.includes(name)) {
              setFormKeys([...formKeys, name])
            }
            if (!formStateToInitialize[name]) {
              initializeFormState(name, { validated: false, valid: false, blur: false, type: 'select', required })
            }
          }}
        />
      </div>
    )
  }

  const renderFieldSet = ({ children }) => {
    return <div className="py-2">{children}</div>
  }

  const renderLegend = ({ children, name }) => {
    return (
      <Heading level="h3" className="mb-2">
        {children}
      </Heading>
    )
  }

  const getNodeParams = (node) => {
    // Workaround - Node with name = 'name' returns true (boolean) from htmr conversion
    const newNode = { ...node }
    if (node?.['data-drupal-selector'] === 'edit-name') {
      newNode.name = 'name'
    }
    return { ...newNode }
  }

  const transform = {
    form: (node, props) => renderForm({ ...node, ...props }),
    input: (node, props) => renderInput({ ...getNodeParams(node), ...props }),
    textarea: (node, props) => renderInput({ ...getNodeParams(node), ...props, type: 'textarea' }),
    label: (node, props) => renderLabel({ ...node, ...props }),
    select: (node, props) => renderSelect({ ...getNodeParams(node), ...props }),
    fieldset: (node, props) => renderFieldSet({ ...node, ...props }),
    legend: (node, props) => renderLegend({ ...node, ...props }),
    div: (node, props) => {
      /* This block is used to create checkboxes with single option (boolean) e.g. GDPR consent check */
      let createdInput
      let createdLabel
      let createdDescription

      if (node?.className?.includes('field--name-field-maximum-upload-size')) {
        return null
      }

      if (node?.className?.includes('form-type-checkbox')) {
        /* eslint-disable no-unused-expressions, consistent-return */
        node?.children?.forEach((child) => {
          if (child?.props?.className?.includes('form-boolean--type-checkbox')) {
            createdInput = child?.props
          }

          if (child?.props?.className?.includes('form-item__label') && child?.props?.children) {
            createdLabel = child?.props?.children?.[0]
          }

          if (child?.props?.className?.includes('description') && child?.props?.children) {
            child?.props?.children?.forEach((childsChild) => {
              if (childsChild?.props?.children?.[0]) {
                createdDescription = childsChild?.props?.children?.[0] || ''
              }
            })
          }
        })

        /* eslint-enable no-unused-expressions, consistent-return */
        if (createdInput && createdLabel) {
          return (
            <div className="js-form-item form-item js-form-type-checkbox form-type--checkbox form-type--boolean">
              {renderInput({ ...createdInput, label: createdLabel, disclaimer: createdDescription })}
            </div>
          )
        }
      }

      if (node?.className?.includes('form-type-managed-file')) {
        let fidsInput
        let required = false
        const description = []
        /* eslint-disable no-unused-expressions, consistent-return */
        node?.children?.forEach((child) => {
          if (child?.props?.className?.includes('form-managed-file')) {
            child?.props?.children?.forEach((childsChild) => {
              if (childsChild?.props?.type === 'file') {
                createdInput = childsChild?.props
              }
              if (childsChild?.props?.name?.includes('fids')) {
                fidsInput = childsChild?.props
              }
            })
          }

          if (child?.props?.id?.includes('label')) {
            createdLabel = child?.props?.children?.[0]
            if (child?.props?.className?.includes('form-required')) {
              required = true
            }
          }

          if (child?.props?.className?.includes('description') && child?.props?.children) {
            child?.props?.children?.forEach((childsChild) => {
              if (childsChild?.props?.children) {
                description.push(childsChild?.props?.children?.filter((children) => children))
              }
            })
          }
        })
        /* eslint-enable no-unused-expressions, consistent-return */

        if (createdInput) {
          return (
            <div className="js-form-item form-item js-form-type-managed-file f…rm-item-document form-item-document">
              {renderFile({ ...createdInput, required, label: createdLabel, description, fidsInput })}
            </div>
          )
        }
      }

      return <div {...{ ...node, ...props }} />
    }
  }

  return (
    <>
      {entityLabel && <Heading text={entityLabel} level="h2" />}
      <Html value={entityRendered} transform={transform} />
    </>
  )
}
export default Form
