import React, { useEffect } from 'react'
import tw, { css, styled } from 'twin.macro'
import FormattedText from '~utils/text'

const InputLabel = styled.label(({ disabled }) => [tw`text-c-input-options-label mb-2`, disabled && tw`opacity-40`])

const OptionLabel = styled.label(({ haserror, last }) => [
  tw`mb-4 text-sm text-c-input-options-label flex items-center relative overflow-hidden`,
  haserror && tw`text-c-input-options-error`,
  last && tw`mb-0`
])

const CheckboxInput = styled.input(({ disabled, haserror }) => [
  css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
  `,
  tw`bg-white mr-3 w-radio h-radio overflow-hidden border border-solid border-c-input-options-checkboxColour appearance-none`,
  tw`checked:(bg-c-input-options-checkboxCheckedColour border-c-input-options-checkboxCheckedColour)`,

  !disabled && tw`hocus:(cursor-pointer)`,

  disabled && tw`opacity-40 cursor-not-allowed`,
  haserror && tw`border-c-input-options-error`
])

const RadioInput = styled.input(({ disabled, haserror }) => [
  tw`bg-white mr-3 w-radio h-radio rounded-full border border-solid border-c-input-options-radioColour appearance-none`,
  tw`checked:before:(content block w-4 h-4 absolute inset-3 bg-c-input-options-radioBackground border border-solid border-c-input-options-radioCheckedColour rounded-full)`,

  !disabled && tw`hocus:(cursor-pointer)`,
  disabled && tw`opacity-40 cursor-not-allowed`,
  haserror && tw`border-c-input-options-error`
])

const ErrorMessage = styled.div`
  ${tw`text-xs text-c-input-options-error pt-1`}
`

export const InputOptionGroup = ({
  id,
  field = {},
  label = '',
  radios = true,
  disabled = false,
  options = [],
  haserror = false,
  focus = false,
  tabIndex,
  onUpdate = () => {
    /* noop */
  },
  onInitialRender = () => {
    /* noop */
  }
}) => {
  const ref = React.useRef()

  useEffect(() => {
    if (focus && ref?.current) {
      ref.current.focus()
    }
  }, [focus, ref])

  useEffect(() => {
    onInitialRender()
  }, [])

  const type = radios ? 'radio' : 'checkbox'

  const onChangeHandler = (e) => {
    const newGroupOptions = [
      ...options.map((opt) => {
        if (type === 'radio') {
          if (opt.name === e.target.value) {
            return { ...opt, checked: e.target.checked }
          }
          return { ...opt, ...(opt.disabled ? {} : { checked: false }) }
        }
        if (opt.name === e.target.value) {
          return { ...opt, checked: e.target.checked }
        }
        return opt
      })
    ]

    onUpdate(newGroupOptions)
  }

  return (
    <div className="flex flex-col flex-wrap" tabIndex={tabIndex || undefined}>
      {label && (
        <InputLabel disabled={disabled} htmlFor={field.name}>
          {label}
        </InputLabel>
      )}
      {options.map((option, i) => (
        <OptionLabel
          ref={ref}
          key={option.name}
          disabled={disabled || option.disabled}
          haserror={haserror}
          className={disabled || option.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
          last={i === options.length - 1}
          tabIndex={0}
          onKeyPress={(e) => {
            const code = e.keyCode || e.charCode
            if (code === 13 || code === 32) {
              e.preventDefault()
              onChangeHandler({ target: { value: option.name, checked: !option?.checked } })
            }
          }}
        >
          {type === 'radio' ? (
            <RadioInput
              id={`${field.id}-${option.name}`}
              name={field.name}
              defaultValue={option.name}
              type="radio"
              disabled={disabled || option.disabled}
              checked={option?.checked || false}
              onChange={(e) => onChangeHandler(e)}
              haserror={haserror}
              aria-describedby={field.ariaDescribedBy}
              tabIndex={-1}
            />
          ) : (
            <CheckboxInput
              id={option.name}
              name={field.name}
              defaultValue={option.name}
              type="checkbox"
              disabled={disabled || option.disabled}
              checked={option?.checked || false}
              onChange={(e) => onChangeHandler(e)}
              haserror={haserror}
              tabIndex={-1}
            />
          )}
          <span className={disabled || option.disabled ? 'opacity-40' : ''}>{option.label}</span>
        </OptionLabel>
      ))}
      {haserror && field.error && (
        <div className="error-message">
          <ErrorMessage>
            <FormattedText format="html" text={field.error} />
          </ErrorMessage>
        </div>
      )}
    </div>
  )
}
