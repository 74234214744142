import React from 'react'
//
import Announcement from '~storybook/announcements'
import FormattedText from '~utils/text'
import { useLocalStorage } from '~utils/local-storage'

export const ParagraphAnnouncement = ({ announcement }) => {
  if (!announcement) {
    return null
  }

  const { id, fieldAnnouncementType, fieldAnnouncementDescription } = announcement

  if (!id) {
    return null
  }

  const { value: text, format } = fieldAnnouncementDescription

  const [dismissed, setDismissed] = useLocalStorage('dismissed', {})

  const handleOnDismiss = () => {
    if (!Object.prototype.hasOwnProperty.call(dismissed, id)) {
      setDismissed({ [id]: true, ...dismissed })
    }
  }

  if (dismissed[id]) {
    return null
  }

  return (
    <Announcement
      onDismiss={handleOnDismiss}
      text={<FormattedText text={text} format={format} />}
      danger={fieldAnnouncementType === 'important'}
    />
  )
}

export default ParagraphAnnouncement
