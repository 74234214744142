// Get URL parameter helper
export const getUrlParameter = (url, query) => {
  // eslint-disable-next-line
  const name = query.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  // eslint-disable-next-line
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
  const results = regex.exec(url)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const getCookie = (name, initialValue = '') => {
  return (
    (typeof window !== 'undefined' &&
      document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
      }, '')) ||
    initialValue
  )
}
