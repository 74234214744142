/* eslint-disable prefer-destructuring */
import TagManager from 'react-gtm-module'
//
import { getCookie } from '~utils/common'

const NODE_ENV = process.env.NODE_ENV
const GTM_DEV = process.env.GTM_DEV
const gtmId = process.env.GTM_ID

export const gtmInit = () => {
  TagManager.initialize({ gtmId })
}

export const gtmPushEvent = (event, options) => {
  if (gtmId && (NODE_ENV === 'production' || GTM_DEV === 'true')) {
    const { location, prevLocation, ...others } = options
    const consent = getCookie('cookieConsent', null)

    if (consent) {
      try {
        const value = JSON.parse(consent)

        setTimeout(() => {
          window.dataLayer.push({
            event,
            newLocation: location?.pathname,
            prevLocation: prevLocation?.pathname,
            ...others,
            ...value
          })
        }, 50)
      } catch {
        // eslint-disable-next-line no-console
        console.error('invalid cookie data')
      }
    }
  }
}
