import React, { useEffect } from 'react'
import DatePicker, { registerLocale, CalendarContainer } from 'react-datepicker'
import { format } from 'date-fns'
import tw, { styled, css } from 'twin.macro'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import { IntlContext } from 'react-intl'
import '../../../styles/react-datepicker.css'
import FormattedText from '~utils/text'
//

registerLocale('en', en)
registerLocale('fr', fr)
registerLocale('nl', nl)
registerLocale('de', de)

const locales = {
  en: 'en',
  nl: 'nl',
  fr: 'fr',
  de: 'de'
}
const localesObj = {
  en,
  nl,
  fr,
  de
}

const StyledCalendarContainer = styled(CalendarContainer)`
  ${tw`border border-c-calendar-border shadow-lg rounded-none`}

  &, .react-datepicker {
    ${tw`bg-white border-solid border-c-calendar-borderWrapper rounded-none`}

    &__header {
      ${tw`bg-transparent border-b-0`}

      .react-datepicker__current-month {
        ${tw`pt-4 pb-4 md:pt-5 md:pb-5`}
      }
    }
    &__month {
      ${tw`px-0 pb-0 md:px-5 md:pb-3`}
    }
    &__current-month,
    &-time__header,
    &-year-header {
      ${tw`text-c-calendar-headerText font-normal`}
    }
    &__navigation--previous,
    &__navigation--next {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='%23323341' d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'%3E%3C/path%3E%3C/svg%3E");
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      border: 0;
    }
    &__navigation--next {
      ${tw`w-5 h-5 right-2 md:right-8 top-4 md:top-5 outline-none`}
    }
    &__navigation--previous {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='%23323341' d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'%3E%3C/path%3E%3C/svg%3E");
      ${tw`w-5 h-5 left-2 md:left-8 top-4 md:top-5 outline-none`}
    }
    &__day {
      ${tw`m-0 py-3 px-3 h-10 w-10 text-sm rounded-none bg-transparent text-c-calendar-dayText font-medium outline-none`}
      &-name {
        ${tw`m-0 py-3 px-3 h-10 w-10 text-sm rounded-none bg-transparent text-c-calendar-dayText font-normal`}
      }
      &-names {
        ${tw`border-solid border-c-calendar-border border-t pt-0 md:pt-2`}
      }
      &--keyboard-selected {
        ${tw`bg-transparent text-c-calendar-dayText font-medium`}
      }
      &:hover {
        ${tw`rounded-none bg-c-calendar-bg text-c-calendar-dayTextHover`}
      }
      &--selected,
      &--selected:hover {
        ${tw`bg-c-calendar-dayTextHover text-white`}
      }
      &--in-range,
      &--in-selecting-range,
      &--in-range:hover,
      &--in-selecting-range:hover {
        ${tw`bg-c-calendar-dayText text-white`}
      }
      &--range-end,
      &--range-start,
      &--selecting-range-start,
      &--range-start:hover,
      &--selecting-range-start:hover {
        ${tw`bg-c-calendar-dayTextHover text-white`}
      }
      &--outside-month,
      &--outside-month:hover {
        ${tw`bg-transparent text-c-calendar-dayTextAlt`}
      }
    }
  }
`

const InputLabel = styled.label(({ disabled }) => [tw`text-c-calendar-textInput mb-2`, disabled && tw`opacity-40`])

const TextInput = styled.input(({ haserror, disabled }) => [
  css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='%23323341' d='M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 25px 40px;
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
  `,
  tw`w-full min-h-input cursor-pointer relative py-2 px-4 pr-12 text-sm text-c-calendar-textInput border border-solid border-c-calendar-textInputBorder placeholder-c-calendar-textInputPlaceholder rounded-sm outline-none`,
  tw`transform transition-all duration-200`,

  tw`hover:(border-c-calendar-textInputPlaceholder)`,
  tw`focus:(border-c-calendar-textInputBorderFocus)`,

  haserror && tw`text-c-calendar-textError border-c-calendar-textError`,
  disabled && tw`opacity-40 cursor-not-allowed`
])

const ErrorMessage = styled.div`
  ${tw`text-xs text-c-calendar-textError pt-1`}
`

export const CalendarInput = ({
  handleChange,
  dates,
  name = '',
  label = '',
  disabled = false,
  haserror = false,
  error = '',
  placeholder = '',
  dateRange = false,
  onInitialRender = () => {
    /* noop */
  },
  ...rest
}) => {
  const intl = React.useContext(IntlContext)
  const [startDate, setStartDate] = React.useState(dates?.startDate || null)
  const [endDate, setEndDate] = React.useState(dates?.endDate || null)

  useEffect(() => {
    onInitialRender()
  }, [])

  const onRangeChange = (newDates) => {
    const [start, end] = newDates

    setStartDate(start)
    setEndDate(end)

    handleChange({ startDate: start, endDate: end })
  }

  const onSingleChange = (newDate) => {
    setStartDate(newDate)
    handleChange({ startDate: newDate })
  }

  const props = {
    onChange: onSingleChange,
    locale: locales[intl?.locale] || 'en'
  }

  if (dateRange) {
    props.startDate = startDate
    props.endDate = endDate
    props.selectsRange = true
    props.onChange = onRangeChange
  }

  // use datefns
  const DatePickerInput = React.forwardRef(({ value, onClick }, ref) => {
    let str = value
    if (dateRange && startDate && endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)

      str = `${format(start, 'EEEEEE d LLLL, yyyy', { locale: localesObj[props.locale] })} - ${format(
        end,
        'EEEEEE d LLLL, yyyy',
        { locale: localesObj[props.locale] }
      )}`
    } else if (startDate) {
      const start = new Date(startDate)
      str = `${format(start, 'EEEEEE d LLLL, yyyy', { locale: localesObj[props.locale] })}`
    }

    return (
      <TextInput
        ref={ref}
        type="text"
        haserror={haserror}
        disabled={disabled}
        placeholder={placeholder}
        value={str || value}
        autoComplete="off"
        onFocus={onClick}
        readOnly
      />
    )
  })

  const DatePickerContainer = ({ className, children }) => (
    <StyledCalendarContainer className={`calendar-container ${className}`}>{children}</StyledCalendarContainer>
  )

  const calculateShouldCloseOnSelect = () => {
    if (!dateRange) {
      return true
    }
    if (startDate && !endDate) {
      return true
    }
    if (!startDate) {
      return false
    }
    if (startDate && endDate) {
      return false
    }
    return true
  }

  const shouldCloseOnSelect = calculateShouldCloseOnSelect()

  return (
    <div className="flex flex-col flex-wrap relative">
      {label && (
        <InputLabel htmlFor={name} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <DatePicker
        selected={startDate}
        {...props}
        {...rest}
        customInput={<DatePickerInput />}
        calendarContainer={DatePickerContainer}
        shouldCloseOnSelect={shouldCloseOnSelect}
        selectsRange={dateRange}
      />
      {haserror && error && (
        <ErrorMessage>
          <FormattedText format="html" text={error} />
        </ErrorMessage>
      )}
    </div>
  )
}
