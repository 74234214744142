import {
  Add,
  Menu as Burger,
  DateRange as Calendar,
  ArrowDownward as ArrowDown,
  ArrowBack as ArrowLeft,
  ArrowForward as ArrowRight,
  ArrowUpward as ArrowUp,
  InfoOutlined as InfoOutline,
  Info as InfoFilled,
  MyLocation as Locate,
  LocationOn as LocationPin,
  Search,
  Star,
  ChatBubble as CC,
  Language as Website,
  Share,
  Check,
  KeyboardArrowDown as ChevronDown,
  ChevronLeft,
  ChevronRight,
  KeyboardArrowUp as ChevronUp,
  Close,
  Schedule as Time,
  Warning as Error,
  OpenInNew as ExternalLink,
  Tune as Filter,
  PlayArrow as Play,
  PersonOutline as Profile,
  GetApp as Download,
  DragHandle,
  FullscreenExit as MapContract,
  Notifications as Notification,
  Mail,
  Pause,
  Phone as Telephone,
  PhoneIphone as SmartPhone,
  Fullscreen,
  ZoomOutMap as FullscreenContract,
  Refresh,
  Remove,
  CropFree as MapExpand,
  VolumeOff as SoundOff,
  VolumeUp as SoundOn,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  RssFeed
} from '@material-ui/icons'

export const Icons = {
  Add: Add,
  Burger: Burger,
  Calendar: Calendar,
  ArrowDown: ArrowDown,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  ArrowUp: ArrowUp,
  InfoOutline: InfoOutline,
  InfoFilled: InfoFilled,
  Locate: Locate,
  LocationPin: LocationPin,
  Search: Search,
  Star: Star,
  CC: CC,
  Website: Website,
  Share: Share,
  Check: Check,
  ChevronDown: ChevronDown,
  ChevronLeft: ChevronLeft,
  ChevronRight: ChevronRight,
  ChevronUp: ChevronUp,
  Close: Close,
  Time: Time,
  Error: Error,
  ExternalLink: ExternalLink,
  Filter: Filter,
  Play: Play,
  Profile: Profile,
  Download: Download,
  DragHandle: DragHandle,
  MapContract: MapContract,
  Notification: Notification,
  Mail: Mail,
  Pause: Pause,
  Telephone: Telephone,
  SmartPhone: SmartPhone,
  Fullscreen: Fullscreen,
  FullscreenContract: FullscreenContract,
  Refresh: Refresh,
  Remove: Remove,
  MapExpand: MapExpand,
  SoundOff: SoundOff,
  SoundOn: SoundOn,
  Facebook: Facebook,
  Instagram: Instagram,
  LinkedIn: LinkedIn,
  Twitter: Twitter,
  RssFeed: RssFeed
}

export default Icons
