import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
//

const SITE_URL = process.env.SITE_URL || ''

const renderLink = ({ href, children }) => {
  const props = {}

  const to = href.replace(SITE_URL, '')

  if (to.startsWith('/')) {
    return <Link to={to}>{children}</Link>
  }

  if (to.startsWith('http')) {
    props.target = '_blank'
    props.rel = 'noreferrer nofollow'
  }

  return (
    <a href={to} {...props}>
      {children}
    </a>
  )
}

const renderers = {
  link: ({ node, ...props }) => renderLink({ ...props })
}

const Markdown = ({ text }) => <ReactMarkdown renderers={renderers} source={text} />

export default Markdown
